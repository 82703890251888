jQuery(function($) {'use strict',

	
	// all Parallax Section
	$(window).load(function(){'use strict',
		$("#services").parallax("50%", 0.3);
		$("#clients").parallax("50%", 0.3);
	});
	
	// portfolio filter
	$(window).load(function(){'use strict',
		$portfolio_selectors = $('.portfolio-filter >li>a');
		if($portfolio_selectors!='undefined'){
			$portfolio = $('.portfolio-items');
			$portfolio.isotope({
				itemSelector : '.col-sm-3',
				layoutMode : 'fitRows'
			});
			
			$portfolio_selectors.on('click', function(){
				$portfolio_selectors.removeClass('active');
				$(this).addClass('active');
				var selector = $(this).attr('data-filter');
				$portfolio.isotope({ filter: selector });
				return false;
			});
		}
	});

	// Contact form validation
	var form = $('.contact-form');
	form.button(function () {'use strict',
		$this = $(this);
		$.post($(this).attr('action'), function(data) {
			$this.prev().text(data.message).fadeIn().delay(3000).fadeOut();
		},'json');
		return false;
	});


	// Navigation Scroll
	$(window).scroll(function(event) {
		Scroll();
	});

	$('.navbar-collapse ul li a').click(function() {  
        if($(this.hash).offset()){
            $('html, body').animate({scrollTop: $(this.hash).offset().top - 79}, 1000);
        return false;
        }
	});
	var windowWidth = $(window).width();
	
	if(windowWidth <= 767) { // is mobile 
		$('.navbar-collapse a').click(function(){
	   		$(".navbar-collapse").collapse('hide');
		});
	}
    
    // Pop up close window
    var modal = document.getElementById('checked-popup');
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }
    
    // Resize navbar icon on scroll
    window.onscroll = function() {
      growShrinkLogo()
    };

    // Resize bar and hide/enable button for request
    function growShrinkLogo() {
      var Logo = document.getElementById("Logo");
      var calculator = document.getElementById("calc-request");
      if(calculator) {
        var calc_offset = getOffset(calculator);
      }
      else {
        var calc_offset = 0;
      }
      var myrequest = document.getElementById("calc-btn4");
      if (Logo) {
        if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
            Logo.style.width = '130px';
        } else {
            Logo.style.width = '175px';
        }
      }
      if (myrequest) {
        if (document.body.scrollTop > calc_offset || document.documentElement.scrollTop > calc_offset) {
            myrequest.style.display = "block";
        } else {
            myrequest.style.display = "none";
        }
      }
    }
});

// Preloder script
jQuery(window).load(function(){'use strict';
	$(".preloader").delay(1600).fadeOut("slow").remove();
});

//Preloder script
jQuery(window).load(function(){'use strict';

	// Slider Height
	var slideHeight = $(window).height();
	$('#home .carousel-inner .item, #home .video-container').css('height',slideHeight);

	$(window).resize(function(){'use strict',
		$('#home .carousel-inner .item, #home .video-container').css('height',slideHeight);
	});

});
function getOffset(el) {
  el = el.getBoundingClientRect();
  var scroll = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
  var top = el.top + scroll;
  return top;
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function getInitialDays() {
    var dayValue = getCookie("borrowdays");
    if(dayValue == "") {
        return 7;
    }
    else {
        return dayValue;
    }
}

function getInitialQuantity() {
    var quantityValue = getCookie("borrowquantity");
    if(quantityValue == "") {
        return 1000;
    }
    else {
        return quantityValue;
    }
}

// User define function
function Scroll() {
	var contentTop      =   [];
	var contentBottom   =   [];
	var winTop      =   $(window).scrollTop();
	var rangeTop    =   200;
	var rangeBottom =   500;
	$('.navbar-collapse').find('.scroll a').each(function(){
		contentTop.push( $( $(this).attr('href') ).offset().top);
		contentBottom.push( $( $(this).attr('href') ).offset().top + $( $(this).attr('href') ).height() );
	})
	$.each( contentTop, function(i){
		if ( winTop > contentTop[i] - rangeTop ){
			$('.navbar-collapse li.scroll')
			.removeClass('active')
			.eq(i).addClass('active');			
		}
	})

};

$('#calc-btn0').on('click', function(event) {
    var target = $(this.getAttribute('href'));
    if( target.length ) {
        event.preventDefault();
        $('html, body').stop().animate({
            scrollTop: target.offset().top
        }, 1000);
    }
});

$('#calc-btn1').on('click', function(event) {
    var target = $(this.getAttribute('href'));
    if( target.length ) {
        event.preventDefault();
        $('html, body').stop().animate({
            scrollTop: target.offset().top
        }, 1000);
    }
});

$('#calc-btn2').on('click', function(event) {
    var target = $(this.getAttribute('href'));
    if( target.length ) {
        event.preventDefault();
        $('html, body').stop().animate({
            scrollTop: target.offset().top
        }, 1000);
    }
});

// Calculator slider 
jQuery(document).ready(function(){

// Close button
var span_check = document.getElementsByClassName("close")[0];
if (span_check){
    var modal_check = document.getElementById('checked-popup');
    span_check.onclick = function() {
        modal_check.style.display = "none";
    }
}


/* Button for request - popup */
$('#calc-btn3').on('click', function(event) {
    var modal = document.getElementById('checked-popup');
    modal.style.display = "none";
    var target = $(this.getAttribute('href'));
    if( target.length ) {
        event.preventDefault();
        $('html, body').stop().animate({
            scrollTop: target.offset().top
        }, 1000);
    }
});

$("#sliderdd").roundSlider({
    sliderType: "min-range",
    width: 16,
    min: 1,
    max: 60,
    step: 1,
    showTooltip: true,
    handleSize: 0,
    radius: 110,
    startAngle: 324,
    endAngle: 213,
    handleShape: "square",
    tooltipFormat: "tooltipdays",
    value: getInitialDays()
});

$("#sliderqq").roundSlider({
    sliderType: "min-range",
    width: 16,
    min: 500,
    max: 5000,
    step: 10,
    showTooltip: true,
    handleSize: 0,
    radius: 110,
    startAngle: 322,
    endAngle: 214,
    handleShape: "square",
    tooltipFormat: "tooltipquantity",
    value: getInitialQuantity()
});

});

var globalDays;
var globalQuantity;
var globalInt;

function tooltipquantity(args) {
    globalQuantity = args.value;
    setCookie('borrowquantity', globalQuantity, 1);
	updateValues();
    return "$" + args.value
}

function tooltipdays(args) {
    globalDays = args.value;
    setCookie('borrowdays', globalDays, 1);
	updateValues();
    return args.value + " Días" 
}

function updateValues() {
    var sliderQ = document.getElementById("quantity01");
    
    globalInt = ((parseInt(globalQuantity) * 0.0095342) * parseInt(globalDays));
    
    var sliderD = document.getElementById("days01");
   
    var sliderT = document.getElementById("total01");
    var total = (parseInt(globalQuantity) + globalInt);
    
    var sNumber = total.toFixed(2)

    var digit6 = document.getElementById("digit_6");
    digit6.textContent  = sNumber.charAt(6)
    var digit5 = document.getElementById("digit_5");
    digit5.textContent  = sNumber.charAt(5)
    var digit4 = document.getElementById("digit_4");
    digit4.textContent  = sNumber.charAt(4)
    var digit3 = document.getElementById("digit_3");
    digit3.textContent  = sNumber.charAt(3)
    var digit2 = document.getElementById("digit_2");
    digit2.textContent  = sNumber.charAt(2)
    var digit1 = document.getElementById("digit_1");
    digit1.textContent  = sNumber.charAt(1)
    var digit0 = document.getElementById("digit_0");
    digit0.textContent  = sNumber.charAt(0)
}

var globalCheckCount = 0;
var totalchecks = 6;

function showpopup() {
    
    var check_one   = document.getElementById("one").checked;
    var check_two   = document.getElementById("two").checked;
    var check_three = document.getElementById("three").checked;
    var check_four  = document.getElementById("four").checked;
    var check_five  = document.getElementById("five").checked;
    var check_six   = document.getElementById("six").checked;
    
    if (check_one && check_two && check_three && check_four && check_five && check_six) {
        var x = document.getElementById("checked-popup");
        x.style.display = 'block';
    }
}

